.select-search {
  .select-search__control {
    min-height: 40px;
    border: 1px solid $grey-10;
    background-color: $white;
    border-radius: 5px;
    box-shadow: none;
    @extend .font-size-30;
    color: $grey-135;

    &:hover {
      border-color: $grey-10;
    }

    &--is-focused {
      border-color: $purple-60;
    }
  }

  .select-search__placeholder {
    margin: 0;
    color: $grey-40;
  }

  .select-search__indicator-separator {
    display: none;
  }

  .select-search__value-container {
    padding: 0 8px;
  }

  .select-search__menu {
    margin-top: 4px;
    padding: 5px 0;
    background-color: $white;
    border: 0;
    border-radius: 6px;
    box-shadow: 0px 1px 8px rgba(86, 66, 96, 0.15);
  }

  .select-search__menu-list,
  .select-search__group {
    padding: 0;
  }

  .select-search__group {
    padding: 6px 0 0;

    + .select-search__group {
      margin-top: 8px;
      padding-top: 16px;
      border-top: 1px solid $grey-10;
    }
  }

  .select-search__group-heading {
    margin: 0 0 4px;
    padding: 0 8px;
    @extend .font-size-10;
    font-weight: 500;
    color: $grey-60;
  }

  .select-search__option {
    padding: 8px;
    @extend .font-size-30;
    font-weight: 400;
    line-height: 1;
    background-color: $white;
    color: $grey-135;
    cursor: pointer;
    transition: 300ms all;

    &:hover,
    &--is-focused {
      background-color: $grey-5;
      color: $grey-135;
    }

    &--is-selected {
      background-color: $purple-60;
      color: $white;
    }
  }

  .select-search__arrow {
    position: absolute;
    top: 50%;
    right: 13px;
    width: 7px;
    height: 7px;
    color: $grey-60;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .select-search__menu-notice {
    padding: 8px;
    @extend .font-size-30;
    text-align: left;
    color: $grey-60;
  }

  .select-search__clear-indicator {
    display: none;
  }

  .select-search__multi-value {
    padding: 4px 8px;
    background-color: $grey-5;
    border: 1px solid $grey-10;
    border-radius: 4px;
    font-size: 0;
    color: $grey-80;
  }

  .select-search__multi-value__label {
    padding: 0;
    @extend .font-size-10;
  }

  .select-search__multi-value__remove {
    padding: 0;
    margin-left: 6px;
    cursor: pointer;
    transition: 300ms all;

    &:hover {
      background-color: rgba(0, 0, 0, 0);
      color: $purple-60;
    }
  }

  &--small {
    @extend .font-size-20;

    .select-search__control {
      min-height: 34px;
    }

    .select-search__single-value {
      @extend .font-size-20;
    }

    .select-search__value-container {
      padding: 0 6px;
    }

    .select-search__placeholder {
      color: $grey-135;
    }

    .select-search__control,
    div.select-search__option,
    .select-search__placeholder,
    div.select-search__menu-notice {
      @extend .font-size-20;
    }

    div.select-search__option,
    div.select-search__menu-notice {
      padding: 6px 8px;
    }
  }

  &--error {
    .select-search__control {
      border-color: $orange-60;
    }
  }
}
