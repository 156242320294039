.dialog {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(29, 11, 36, 0.1);
    backdrop-filter: blur(2px);
    pointer-events: none;
    transition: 300ms all;
    z-index: 4;

    &[data-state='open'] {
      animation: fadeIn 0.15s ease forwards;
    }

    &[data-state='closed'] {
      animation: fadeOut 0.15s ease forwards;
    }
  }

  &__content {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 100vh;
    min-height: 52vh;
    max-width: 600px;
    width: 100%;
    margin: 10px auto;
    padding: 30px;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    text-align: left;
    transform: translate(-50%, -50%);
    z-index: 5;
    
    &--admin-dashboard {
      max-width: 90%;
      max-height: 90%;
      overflow: auto;
    }
  }

  &__inner {
    height: fit-content;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    appearance: none;
    background-color: $grey-70;
    color: $white;
    cursor: pointer;
    transform: translate(7px, -7px);
    transition: 300ms all;

    &:hover {
      background-color: $grey-120;
    }
  }

  &__close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    transform: translate(-50%, -50%);
  }
}
