.table {
  overflow: auto;
  max-width: 100%;
  max-height: 70vh;

  &.paginated {
    margin-bottom: 50px; // Height of table foot
  }

  &.shorter-table {
    max-height: 65vh;
  }

  &__main {
    min-width: 100%;
    border-collapse: collapse;
    text-align: left;
  }

  &__foot {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-collapse: collapse;
    background-color: $white;
  }

  &__row {
    background-color: $white;
    transition: 300ms all;
    cursor: pointer;

    &:hover {
      background-color: $off-white;
    }

    &--no-hover {
      cursor: initial;

      &:hover {
        background-color: $white;
      }
    }
  }

  &__heading {
    padding: 7px 10px;
    background-color: $white;
    white-space: nowrap;

    &--center {
      text-align: center;
    }

    &:first-of-type,
    &:last-of-type {
      padding: 7px 20px;
    }
  }

  &__label,
  &__sort-button {
    display: inline-block;
    vertical-align: middle;
  }

  &__label {
    @extend .font-size-10;
    font-weight: 600;
    line-height: 16px;
    color: $grey-70;
    text-transform: uppercase;
  }

  &__sort-button {
    position: relative;
    width: 10px;
    height: 16px;
    margin-left: 5px;
    padding: 0;
    appearance: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    color: $grey-60;
    cursor: pointer;
    transition: 300ms;

    &:hover {
      color: $grey-120;
    }
  }

  &__sort-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 6px;
    transform: translate(-50%, -50%);
    transition: 300ms;

    &--rotated {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &__cell {
    padding: 8px 10px;
    border-top: 1px solid $grey-10;

    &:first-of-type,
    &:last-of-type {
      padding: 8px 20px;
    }

    &--center {
      text-align: center;
    }
  }

  &__text {
    max-width: 20vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @extend .font-size-25;
    color: $grey-100;

    &--small {
      @extend .font-size-10;
      color: $grey-70;
    }

    &--bold {
      color: $grey-80;
      font-weight: 600;
    }

    &--short {
      max-width: 15vw;
    }
  }

  &__author-wrap {
    align-items: center;
    display: flex;
    min-width: max-content;
  }

  &__author,
  &__author-text {
    vertical-align: middle;
  }

  &__author-container {
    flex-direction: column;
    display:flex;
    margin-left: 8px
  }

  &__author-text {
    @extend .font-size-25;
    color: $grey-100;
  }

  &__author-email {
    @extend .font-size-10;
    color: $grey-70;
  }

  &__footer-col {
    padding: 8px 20px;
    border-top: 1px solid $grey-10;
  }

  &__footer-col--right {
    text-align: right;
  }

  &__select-wrap,
  &__count-text,
  &__pagination-button,
  &__input-wrapper,
  &__input-label,
  &__input {
    display: inline-block;
    vertical-align: middle;
  }

  &__select-wrap {
    position: relative;
  }

  &__select {
    min-width: 48px;
    appearance: none;
    padding: 7px 8px;
    border-radius: 4px;
    border: 1px solid $grey-10;
    @extend .font-size-20;
    color: $grey-60;
  }

  &__select-arrow {
    position: absolute;
    top: 50%;
    right: 8px;
    width: 7px;
    height: 4px;
    color: $grey-60;
    transform: translateY(-50%);
    pointer-events: none;
  }

  &__count-text {
    margin-left: 16px;
    @extend .font-size-20;
    color: $grey-80;
  }

  &__pagination-button {
    position: relative;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    appearance: none;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0);
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    ;
    color: $grey-60;
    transition: 300ms all;
    cursor: pointer;

    &:disabled {
      color: $grey-40;
      cursor: not-allowed;
    }

    &:focus {
      border-color: $purple-60;
    }

    &:hover {
      background-color: $grey-5;
    }
  }

  &__pagination-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 11px;
    transform: translate(-50%, -50%);

    &--prev,
    &--next {
      width: 7px;
      height: 11px;
    }
  }

  &__input-label {
    @extend .font-size-20;
    color: $grey-80;
  }

  &__input {
    margin: 0 8px 0 5px;
    width: 60px;
    height: 28px;
    padding: 5px 8px;
    appearance: none;
    border-radius: 5px;
    border: 1px solid $grey-10;
    @extend .font-size-20;
    color: $grey-40;
  }

  &__loading,
  &__no-results {
    display: flex;
    min-height: 345px;
    width: 100%;
    align-items: center;
    justify-content: center;

    &--10 {
      min-height: 615px;
    }
  }

  &__no-results {
    border-top: 1px solid $grey-10;
  }
}
